import { Link, useLocation } from 'react-router-dom';
import NavHome from './NavButtons/NavHome';
import NavFriends from './NavButtons/NavFriends';
import NavTasks from './NavButtons/NavTasks';
import NavWallet from './NavButtons/NavWallet';
import { useState, useEffect } from 'react';

function NavbarMenu({preloadSuccessfull}) {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('home');
  useEffect(() => {
    setActiveButton(location.pathname);
  }, [location.pathname]);

  if(!preloadSuccessfull) {
    return <div> </div> 
  }
  return (
    <nav className='navbar'>
      <Link to="/home" >{<NavHome activeButton = {activeButton} />}</Link>
      <Link to="/tasks" >{<NavTasks activeButton = {activeButton} />}</Link>
      <Link to="/friends" >{<NavFriends activeButton = {activeButton} />}</Link>
      <Link to="/wallet" >{<NavWallet activeButton = {activeButton} />}</Link>
    </nav>
  );
}

export default NavbarMenu;