import React from "react";

const Energy = ({userEnergy}) => {
    return(
        <div className = 'energy'>
            <div className = 'energy-name'>
                Energy:
            </div>
            <div className = 'energy-count'>
                {
                    `${userEnergy} / 1000`
                }
            </div>
        </div>
    )
}

export default Energy;