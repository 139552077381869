import './App.css';
import Friends from './UI/Friends/Friends';
import Clicker from './UI/Home/Clicker';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tasks from './UI/Tasks/Tasks';
import Wallet from './UI/Wallet/Wallet';
import NavbarMenu from './UI/Navbar/NavbarMenu';
import BackgroundVectors from './UI/BackgroundLine/BackgroundVectors';
import Preload from './UI/Loading/Preload';
import { useEffect, useState } from 'react';


function App() {
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userValue, setUserValue] = useState(null);
  // need to fix
  const [userEnergy, setUserEnergy] = useState(null);
  // const [userFriends, setUserFriends] = useState(null);
  // const [userTonActive, setUserTonActive] = useState(null);
  const [preloadSuccessfull, setPreloadSuccessfull] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const tgUser = await window.Telegram.WebApp.initDataUnsafe;
            if (tgUser) {
                const response = await fetch('/api/userData', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: tgUser.user.id })
                });
                const data = await response.json();
                if(data) {
                  setUserId(tgUser);
                  setUserName(data.userName);
                  setUserPhoto(data.userPhoto);
                  setUserValue(data.userValue);
                  setUserEnergy(data.userEnergy);
                  // setUserFriends(data.userFriends);
                  // setUserTonActive(data.userTonActive);
                }
                console.log('Данные успешно получены');
            }
        } catch (error) {
            console.error('Ошибка при получении данных пользователя:', error);
        }
    };

    fetchUserData();
  }, [])


  useEffect(() => {
    const fetchUserEnergy = async () => {
      try {
        const tgUser = await window.Telegram.WebApp.initDataUnsafe;
        if (tgUser) {
          await fetch('/api/setUserEnergy', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: tgUser.user.id, newValueEnergy: userEnergy })
          });
        }
      } catch (error) {
        console.error('Ошибка при добавлении энергии!', error);
      }
    };

// need add if for fetch
    const interval = setInterval(() => {
      setUserEnergy((prevEnergy) => Math.min(prevEnergy + 3, 1000));
      fetchUserEnergy();
    }, 5000)

    return () => {
      clearInterval(interval);
    }

  }, [preloadSuccessfull])

  return (
    <Router>
      <div className="app">
        <BackgroundVectors/>
        <Routes>
            <Route path='/' element = {
              <Preload 
                userId = {userId} 
                userName = {userName} 
                userValue = {userValue}
                userEnergy = {userEnergy}
                setPreloadSuccessfull = {setPreloadSuccessfull}
              />}/>
            <Route path='/home' element = {
              <Clicker 
                userPhoto = {userPhoto} 
                userName = {userName} 
                userValue = {userValue} 
                setUserValue = {setUserValue} 
                userEnergy = {userEnergy} 
                setUserEnergy = {setUserEnergy}
              />}/>
            <Route path='/friends' element={<Friends/>} />
            <Route path='/tasks' element={<Tasks/>} />
            <Route path='/wallet' element={<Wallet/>} />
        </Routes>
        <NavbarMenu preloadSuccessfull = {preloadSuccessfull}/>
      </div>
    </Router>
  );
}

export default App;