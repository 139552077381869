import React from "react";
import EyeClickerButton from "./EyeClickerButton";

const BodyClickerButton = () => {
    return(
        <div className='gradient-border'>
            <div className='write-wrapper'>
                <div className='eye-wrapper'>
                    <EyeClickerButton isMirrored={false}/>
                    <EyeClickerButton isMirrored={true}/>
                </div>
            </div>
        </div>
    )
}

export default BodyClickerButton;