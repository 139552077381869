import React from "react";

const Wallet = () => {
    return(
        <div className='wallet'>
            wallet
        </div>
    )
}

export default Wallet;