import React from "react";
import logo_X from '../../assets/X.png';

const XSvg = () => {
    return(
        <div className = 'tasks-image-wrapper'>
            <img className = 'tasks-image' src = {logo_X} alt = ''/> 
        </div>
    )
}

export default XSvg;