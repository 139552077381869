import React from "react";

const Vector7 = () => {
    return(
        <div className = 'vector7 fixed'>
            <svg width="91" height="218" viewBox="0 0 91 218" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M90.2445 0.051178C88.7525 181.877 79.5 162.499 0.00158691 217.501" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector7;