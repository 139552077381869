import React, { useState } from "react";
import EyeHeader from "../Common/EyeHeader";
import HeaderTasks from "./HeaderTasks";
import TasksList from "./TasksList";

const Tasks = () => {
    const [tasks, setTasks] = useState([
        {type: 'telegram', name: 'Follow RAY on Telegram', reward: 200},
        {type: 'x', name: 'Follow RAY on X', reward: 200},
        {type: 'ray', name: 'Invite 10 friends', reward: 900},
        {type: 'ray', name: 'Invite 1 friend', reward: 90}
    ]); 

    return(
        <div className='tasks'>
            <EyeHeader/>
            <HeaderTasks lengthList={tasks.length}/>
            <TasksList tasks={tasks}/>
        </div>
    )
}

export default Tasks;