import React from "react";

const NavTasks = ({activeButton}) => {
    return(
        <div className = {`nav-button${activeButton==='/tasks' ? ' is-active-button' : ''}`} >
            <div className = 'nav-svg'>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1_34)">
                <path d="M0 0.953899L0 16.0461C0 16.5729 0.422143 17 0.942883 17H0.98043C1.50117 17 1.92331 16.5729 1.92331 16.0461L1.92331 0.953899C1.92331 0.427074 1.50117 0 0.98043 0H0.942883C0.422143 0 0 0.427074 0 0.953899Z" fill="#ADACAC"/>
                <path d="M3.44171 0.953899L3.44171 16.0461C3.44171 16.5729 3.86385 17 4.38459 17H4.42214C4.94288 17 5.36502 16.5729 5.36502 16.0461L5.36502 0.953899C5.36502 0.427074 4.94288 0 4.42214 0H4.38459C3.86385 0 3.44171 0.427074 3.44171 0.953899Z" fill="#ADACAC"/>
                <path d="M15.5571 0H0.942884C0.422144 0 0 0.427075 0 0.9539V0.991883C0 1.51871 0.422144 1.94578 0.942884 1.94578H15.5571C16.0779 1.94578 16.5 1.51871 16.5 0.991883V0.9539C16.5 0.427075 16.0779 0 15.5571 0Z" fill="#ADACAC"/>
                <path d="M14.5448 3.48193H8.02876C7.50802 3.48193 7.08588 3.90901 7.08588 4.43583V4.47382C7.08588 5.00064 7.50802 5.42772 8.02876 5.42772H14.5448C15.0656 5.42772 15.4877 5.00064 15.4877 4.47382V4.43583C15.4877 3.90901 15.0656 3.48193 14.5448 3.48193Z" fill="#ADACAC"/>
                <path d="M14.5448 6.96387H8.02876C7.50802 6.96387 7.08588 7.39094 7.08588 7.91777V7.95575C7.08588 8.48257 7.50802 8.90965 8.02876 8.90965H14.5448C15.0656 8.90965 15.4877 8.48257 15.4877 7.95575V7.91777C15.4877 7.39094 15.0656 6.96387 14.5448 6.96387Z" fill="#ADACAC"/>
                <path d="M15.5571 15.0542H0.942884C0.422144 15.0542 0 15.4813 0 16.0081V16.0461C0 16.5729 0.422144 17 0.942884 17H15.5571C16.0779 17 16.5 16.5729 16.5 16.0461V16.0081C16.5 15.4813 16.0779 15.0542 15.5571 15.0542Z" fill="#ADACAC"/>
                </g>
                <defs>
                <clipPath id="clip0_1_34">
                <rect width="16.5" height="17" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div>
            <div className='nav-text'>
                Tasks
            </div>
        </div>
    )
}

export default NavTasks;