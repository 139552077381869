import React from "react";

const ClickerCounter = ({userValue}) => {

    return(
        <div className = 'clicker-counter'>
            <span className='clicker-counter-value'> {userValue} </span>
            <span className='clicker-counter-ray'> R </span>
        </div>
    )
}

export default ClickerCounter;