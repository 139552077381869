import React from "react";

const Vector3 = () => {
    return(
        <div className = 'vector3 fixed'>
            <svg width="174" height="539" viewBox="0 0 174 539" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.4955 -0.00201416C54.004 181.823 -125.498 492.001 175 538.5" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector3;