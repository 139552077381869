import React from "react";

const FriendsHeader = ({lengthList}) => {
    
    return(
        <div className="tasks-header">
            <div className='tasks-header-main'>
                <p className='tasks-header-main-h2'> Friends </p>
                <p className='tasks-header-main-count'> {`(${lengthList})`} </p> 
            </div>
            <p className='tasks-header-body'>
                Earn points by inviting friends, <br/>
                get 10% of their earnings
            </p>
        </div>
    )
}

export default FriendsHeader;