import React from "react";

const Vector6 = () => {
    return(
        <div className = 'vector6 fixed'>
            <svg width="146" height="322" viewBox="0 0 146 322" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M145.499 -0.00390625C144.007 181.822 70.287 187.684 -0.25 321.75" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector6;