import React from "react";

const Vector4 = () => {
    return(
        <div className = 'vector4 fixed'>
            <svg width="70" height="740" viewBox="0 0 70 740" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68.9995 0.250183C-71.5002 660.5 71.287 606.435 0.75 740.5" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector4;