import React from "react";

const Vector5 = () => {
    return(
        <div className = 'vector5 fixed'>
            <svg width="59" height="312" viewBox="0 0 59 312" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-0.751469 0.749512C85 87.7495 69.7845 177.685 -0.752319 311.75" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector5;