import React from "react";

const Loading = () => {
    
    return(
        <div className = 'loading'>
            <div className = 'loading-point1'/>
            <div className = 'loading-point2'/>
            <div className = 'loading-point3'/>
        </div>
    )
}

export default Loading;