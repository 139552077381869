import React from "react";
import logo_telegran from '../../assets/Telegram.png';

const TelegramSvg = () => {
    return(
        <div className = 'tasks-image-wrapper'>
            <img className = 'tasks-image' src = {logo_telegran} alt = ''/> 
        </div>
    )
}

export default TelegramSvg;