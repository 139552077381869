import React from "react";

const HeaderTasks = ({lengthList}) => {
    

    return(
        <div className="tasks-header">
            <div className='tasks-header-main'>
                <p className='tasks-header-main-h2'> Tasks </p>
                <p className='tasks-header-main-count'> {`(${lengthList})`} </p> 
            </div>
            <p className='tasks-header-body'>
                Earn points by complenting tasks 
            </p>
        </div>
    )
}

export default HeaderTasks;