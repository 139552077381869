import React from "react";

const Vector2 = () => {
    return(
        <div className = 'vector2 fixed'>
            <svg width="151" height="380" viewBox="0 0 151 380" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M83.8595 -3.99908C82.3675 177.826 -140.25 466.25 150.25 354.001" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>
    )
}

export default Vector2;