import React from "react";

const Vector1 = () => {
    return(
        <div className = 'vector1 fixed'>
            <svg width="156" height="168" viewBox="0 0 156 168" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M156.25 8.99997C118.25 -23.5 -123.75 48.5 85.2505 167.75" stroke="#DCDCDC" strokeWidth="0.5"/>
            </svg>
        </div>

    )
}

export default Vector1;