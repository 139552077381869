import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EyeHeader from "../Common/EyeHeader";
import Loading from "./Loading";


const Preload = ({userId, userName, userValue, userEnergy, setPreloadSuccessfull}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if(userId !== null && userName !== null && userValue !== null && userEnergy !== null) {
            setPreloadSuccessfull(true);
            navigate('/home');
        }
    })

    return(
        <div className = 'preload'>
            <EyeHeader/>
            <Loading/>
            <h1> RAY </h1>
            <p> On TON Blockchain </p>
        </div>
    )
}

export default Preload;