import React from "react";
import Vector1 from "./Vector1";
import Vector2 from "./Vector2";
import Vector3 from "./Vector3";
import Vector4 from "./Vector4";
import Vector5 from "./Vector5";
import Vector6 from "./Vector6";
import Vector7 from "./Vector7";

const BackgroundVectors = () => {
    return(
        <div className = 'backgroud-vectors'>
            <Vector1/>
            <Vector2/>
            <Vector3/>
            <Vector4/>
            <Vector5/>
            <Vector6/>
            <Vector7/>
        </div>
    )
}

export default BackgroundVectors;