import React from "react";
import TasksItem from "./TasksItem";

const TasksList = ({tasks}) => {
    
    return(
        <div className = 'tasks-list'>
            {
                tasks.map((task) => (
                    <TasksItem task={task}/>
                ))
            }
        </div>
    )
}

export default TasksList;