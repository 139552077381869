import React from "react";

const EyeHeader = () => {
    return (
        <div className = 'eye-header'>
            <div className = 'eye-header-left'>
                <svg width="80" height="40" viewBox="0 0 162 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M161.404 48.5357C161.404 75.1635 124.654 81 81.2602 81C37.8657 81 5.27614 73.6071 1.34631 47.25C-2.82316 19.2857 0.465404 0 43.8596 0C87.2541 0 161.404 21.9077 161.404 48.5357Z" fill="#454749"/>
                </svg>
            </div>
            <div className = 'eye-header-right mirrored'>
                <svg width="80" height="40" viewBox="0 0 162 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M161.404 48.5357C161.404 75.1635 124.654 81 81.2602 81C37.8657 81 5.27614 73.6071 1.34631 47.25C-2.82316 19.2857 0.465404 0 43.8596 0C87.2541 0 161.404 21.9077 161.404 48.5357Z" fill="#454749"/>
                </svg>
            </div>
        </div>
    )
}

export default EyeHeader;