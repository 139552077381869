import React, { useEffect, useState } from "react";

const FriendsItem = ({friend}) => {
    const [userName, setUsername] = useState(null);
    const [userPhoto, setUserphoto] = useState(null); 

    useEffect(() => {
        const fetchFriendData = async () => {
            try {
                if (friend.userId) {

                    const response = await fetch('/api/userData', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId: friend.userId })
                    });
                    const data = await response.json();

                    if(data) {
                        setUsername(data.userName);
                        setUserphoto(data.userPhoto);
                    }
                    console.log('Данные успешно получены (friend)');
                }
            } catch (error) {
                console.error('Ошибка при получении данных пользователя (friend):', error);
            }
        };

        fetchFriendData();
    }, [])


    return(
        <div className = 'friends-item'>
            <div className = 'friends-item-block'>
                <div className = 'friends-item-block-photo'>
                    {
                        userPhoto ? <img src = {userPhoto} alt = ''/> : <p> Loading logo... </p>
                    }
                </div>
                <div className = 'friends-item-block-username'>
                    {
                        userName ? <p> {userName} </p> : <p> Loading... </p>
                    }
                </div>
            </div>
            <div className = 'friends-item-ray'>
                <p className = 'friends-item-ray-count'> 3213 </p>
                <p className = 'friends-item-ray-logo'> R </p>
            </div>
        </div>
    )
}

export default FriendsItem;