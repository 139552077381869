import React, {useRef} from 'react'
import BodyClickerButton from './BodyClickerButton';

const ClickerButton = ({userValue, setUserValue, userEnergy, setUserEnergy}) => {

    const detectDeviceType = () => {
        const userAgent = navigator.userAgent;
        if (/Mobi|Android|iPhone|iPad|iPod/i.test(userAgent)) {
          return 'mobile';
        } else {
          return 'desktop';
        }
    }

    const buttonRef = useRef(null);
    const deviceType = detectDeviceType(); 

    const handleClick = () => {
        if(userEnergy > 3) {
            setUserValue(userValue + 1);
            setUserEnergy(userEnergy - 3); 
        }
    }

    // need update energy
    const handleTouchEnd = (event) => {
        // !!!!!!!!!!!!! fix them !!!!!!!!!!!
        if(event.changedTouches.length * 3 > userEnergy) {
            setUserValue(userValue + Math.floor(userEnergy / 3));
            // setUserEnergy(userEnergy - (event.BodyClickerButton.length - Math.floor(userEnergy / 3)));
            setUserEnergy(userEnergy - (event.changedTouches.length - Math.floor(userEnergy / 3)));
        }
        else {
            setUserValue(userValue + event.changedTouches.length);
            setUserEnergy(userEnergy - 3);
        }
      };

    return(
        <div className='clicker-button-wrapper'>
            <button 
                ref={buttonRef} 
                type='button' 
                className = 'clicker-button'
                onTouchEnd = {deviceType === 'mobile' ? handleTouchEnd : undefined} 
                onClick = {deviceType === 'desktop' ? handleClick : undefined}
            >
                <BodyClickerButton/>
            </button>
        </div>
    )
}

export default ClickerButton;