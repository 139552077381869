import React, { useState } from "react";
import EyeHeader from "../Common/EyeHeader";
import FriendsHeader from "./FriendsHeader";
import FriendsList from "./FriendsList";
import FriendsButton from "./FriendsButton";

const Friends = () => {
    const [friends] = useState([
        {userId: '732981438', rayCount: 123},
        {userId: '1149309626', rayCount: 3321},
    ])
    return(
        <div className = 'friends'>
            <EyeHeader/> 
            <FriendsHeader lengthList={friends.length}/>
            <FriendsList friends={friends}/>
            <FriendsButton/>
        </div>
    )
}

export default Friends;