import React from "react";

const PassiveFarmButton = (props) => {
    return(
        <div className='passive-farm-button-wrapper'>
            <button className='passive-farm-button'>
                Start passive farm
            </button>
        </div>
    )
}

export default PassiveFarmButton;