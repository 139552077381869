import React from "react";
import FriendsItem from "./FriendsItem";

const FriendsList = ({friends}) => {
    
    return(
        <div className = 'friends-list'>
            {
                friends.map((friend) => (
                    <FriendsItem friend={friend}/>
                ))
            }
        </div>
    )
}

export default FriendsList;