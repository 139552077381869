import React from "react";

const Profile = ({userName, userPhoto}) => {

    return(
        <div className='profile'>
        {
            userPhoto ? <img src = {userPhoto} alt = 'avatarka'/> : 'Loading avatar...'
        }
            <p className = 'profile-user-name'>
                {
                    userName ? userName : 'Loading username...'
                }
            </p>
        </div>
    )
}

export default Profile;