import React, {useEffect} from "react";
import ClickerButton from "./ClickerButton";
import ClickerCounter from "./ClickerCounter";
import Energy from "./Energy";
import PassiveFarmButton from "./PassiveFarmButton";
import Profile from "./Profile";

const Clicker = ({userPhoto, userName, userValue, setUserValue, userEnergy, setUserEnergy}) => {

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const tgUser = await window.Telegram.WebApp.initDataUnsafe;
                if (tgUser) {
                    await fetch('/api/setUserClick', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId: tgUser.user.id, newValueClick: userValue, newValueEnergy: userEnergy })
                    });
                }
            } catch (error) {
                console.error('Ошибка при обновлении кликов!', error);
            }
        };

        fetchUserData();
    }, [userValue, userEnergy]);

    return(
        <div className = 'clicker'>
            <Profile userPhoto = {userPhoto} userName = {userName} />
            <ClickerCounter userValue = {userValue}/>
            <Energy userEnergy = {userEnergy}/>
            <ClickerButton 
                userValue = {userValue} 
                setUserValue = {setUserValue}
                userEnergy = {userEnergy}
                setUserEnergy = {setUserEnergy}
            />
            <PassiveFarmButton/>
        </div>
    )
}

export default Clicker;