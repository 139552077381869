import React from "react";

const FriendsButton = () => {
    

    return(
        <div className = 'frineds-button-wrapper'>
            <button className = 'friends-button' type = 'button'>
                Invite Friends
            </button>
        </div>
    )
}

export default FriendsButton;