import React from "react";

const NavWallet = ({activeButton}) => {
    return(
        <div className = {`nav-button${activeButton==='/wallet' ? ' is-active-button' : ''}`}>
            <div className = 'nav-svg'>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <g clipPath="url(#clip0_1_49)">
                <path d="M0 0.953899L0 16.0461C0 16.5729 0.422143 17 0.942883 17H0.98043C1.50117 17 1.92331 16.5729 1.92331 16.0461L1.92331 0.953899C1.92331 0.427074 1.50117 0 0.98043 0H0.942883C0.422143 0 0 0.427074 0 0.953899Z" fill="#ADACAC"/>
                <path d="M14.5767 0.953899L14.5767 16.0461C14.5767 16.5729 14.9988 17 15.5195 17H15.5571C16.0778 17 16.5 16.5729 16.5 16.0461V0.953899C16.5 0.427074 16.0778 0 15.5571 0H15.5195C14.9988 0 14.5767 0.427074 14.5767 0.953899Z" fill="#ADACAC"/>
                <path d="M15.5571 0H0.942884C0.422144 0 0 0.427076 0 0.9539V0.991883C0 1.51871 0.422144 1.94578 0.942884 1.94578H15.5571C16.0779 1.94578 16.5 1.51871 16.5 0.991883V0.9539C16.5 0.427076 16.0779 0 15.5571 0Z" fill="#ADACAC"/>
                <path d="M15.5571 3.0723H0.942884C0.422144 3.0723 0 3.49937 0 4.0262V4.06418C0 4.591 0.422144 5.01808 0.942884 5.01808H15.5571C16.0779 5.01808 16.5 4.591 16.5 4.06418V4.0262C16.5 3.49937 16.0779 3.0723 15.5571 3.0723Z" fill="#ADACAC"/>
                <path d="M15.5571 6.14459H0.942884C0.422144 6.14459 0 6.57167 0 7.09849V7.13648C0 7.6633 0.422144 8.09038 0.942884 8.09038H15.5571C16.0779 8.09038 16.5 7.6633 16.5 7.13648V7.09849C16.5 6.57167 16.0779 6.14459 15.5571 6.14459Z" fill="#ADACAC"/>
                <path d="M15.5571 15.0542H0.942884C0.422144 15.0542 0 15.4813 0 16.0081V16.0461C0 16.5729 0.422144 17 0.942884 17H15.5571C16.0779 17 16.5 16.5729 16.5 16.0461V16.0081C16.5 15.4813 16.0779 15.0542 15.5571 15.0542Z" fill="#ADACAC"/>
                </g>
                <defs>
                <clipPath id="clip0_1_49">
                <rect width="16.5" height="17" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div>
            <div className='nav-text'>
                Wallet
            </div>
        </div>
    )
}

export default NavWallet;