import React from "react";
import TelegramSvg from "../Common/TelegramSvg";
import XSvg from "../Common/XSvg";

const TasksItem = ({task}) => {
    
    
    return(
        <div className = 'tasks-item'>
            <div className = 'task-item-block'>
                <div className = 'tasks-item-logo'>
                {
                    (task.type === 'telegram') ? <TelegramSvg/>  : (task.type === 'x') ? <XSvg/> : 
                    <div className = 'tasks-item-ray'> R </div>
                }
                </div>
                <div className="tasks-item-body">
                    <p> {task.name} </p>
                    <div className="tasks-item-reward">
                        <p> {`+${task.reward}`}</p>
                        <p className = "tasks-item-reward-ray"> R </p>
                    </div> 
                </div>
            </div>

            <button className = 'task-item-button' type = 'button'>
                Start
            </button>
        </div>
    )
}

export default TasksItem;